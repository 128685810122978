import React, { useMemo } from 'react'
import {
  Button,
  Carousel,
  CarouselIndicator,
  CarouselSlides,
  Dropdown,
  Icon,
  NavStepperCard,
  NavStepperContent,
  NavStepperFooter,
  useNavStepper,
} from '@mondra/ui-components'
import { useInviteData } from 'hooks/useInviteData'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { useCompany } from 'hooks/useCompany'

export function Introduction() {
  const { nextStep } = useNavStepper()
  const { contact, invite, loading } = useInviteData()
  const { companies, company, isLoading, setCompany } = useCompany()

  const Slides = useMemo(() => {
    const slidesArray = [
      {
        description:
          'Mondra offers out-of-the-box product LCA and improvement tools, dynamically connected to your customers for collaborative action.',
        id: 1,
        image: '/images/intro-slide-1.png',
        title: 'Welcome to Mondra!',
      },
      {
        description: `When ${invite.retailerCompanyName} invited you to Mondra they gave us some information about the brands that you supply them with. Time to check it’s all correct!`,
        id: 2,
        image: '/images/intro-slide-2.png',
        title: 'Review your submitted info',
      },
      {
        description:
          'Confirm your organisation’s basic company information so we know that all of the data we hold is up to date.',
        id: 3,
        image: '/images/intro-slide-3.png',
        title: 'About your company/organisation',
      },
      {
        description:
          'Make sure that details we have been supplied about you are correct and up to date. Your user account will be created with this information.',
        id: 4,
        image: '/images/intro-slide-3.png',
        title: 'About you',
      },
      {
        description:
          'Review and confirm your company information and initialise the creation of your account within Mondra.',
        id: 5,
        image: '/images/intro-slide-4.png',
        title: 'Manage and create your company',
      },
    ]
    if (companies.length > 0) {
      return slidesArray.filter(s => s.id !== 3)
    }
    return slidesArray
  }, [invite.retailerCompanyName, companies.length])

  const companyOptions = useMemo(() => {
    return companies.map(comp => ({ ...comp, label: comp.name }))
  }, [companies])

  if (loading) {
    return (
      <div className="p-4 px-24 mt-12">
        <div>
          <SkeletonLoader className="h-6 w-64" />
          <SkeletonLoader className="h-12 w-[700px] mt-2" />
          <SkeletonLoader className="h-4 w-[500px] mt-4" />
        </div>
      </div>
    )
  }

  return (
    <NavStepperCard>
      <NavStepperContent className="flex flex-col gap-4 md:flex-row px-8 my-4 h-20">
        <div className="flex flex-col justify-center flex-1 min-h-[512px]">
          <h2 className="text-gray-700 text-5xl font-semibold mb-4">
            Welcome {contact?.firstName}
          </h2>
          <div className="text-base text-coolGray-600 mb-1">
            You have been invited to Mondra from:
          </div>
          <div className="text-lg text-coolGray-900 font-semibold mb-8">
            {invite.retailerCompanyName}
          </div>
          <div className="text-coolGray-700 pr-24">
            <p className="text-sm leading-5 mb-4">
              Your journey to achieving your environmental goals is about to become a lot easier!
            </p>
            <p className="text-sm leading-5 mb-4">
              You have been invited to collaborate with {invite.retailerCompanyName} on your LCA
              data for the full range of products that you supply to them.
            </p>
            <p className="text-sm leading-5 mb-4">
              Once you have confirmed your company information over the following short steps you
              will be able to accelerate your Scope 3 progress by monitoring emissions data and
              improve your assessments with your own primary data.
            </p>
          </div>
          {companies.length > 1 && (
            <div className="mt-10">
              <Dropdown
                placeholder="Please select a company"
                options={companyOptions}
                selected={company}
                disabled={isLoading}
                onChange={setCompany}
              />
            </div>
          )}
        </div>
        <div className="flex flex-1 justify-center items-center bg-coolGray-50 rounded-lg min-h-[512px]">
          <Carousel autoPlay loop className="p-6">
            <CarouselSlides>
              {Slides.map(s => (
                <div key={s.id} className="flex flex-col justify-center items-center gap-y-2">
                  <div className="rounded-lg mb-8 shadow-lg">
                    <img src={s.image} alt="introduction" className="rounded-lg h-[300px]" />
                  </div>
                  <div className="text-lg font-semibold text-coolGray-900 text-center">
                    {s.title}
                  </div>
                  <div className="text-sm leading-5 font-normal text-coolGray-700 text-center">
                    {s.description}
                  </div>
                </div>
              ))}
            </CarouselSlides>
            <CarouselIndicator />
          </Carousel>
        </div>
      </NavStepperContent>
      <NavStepperFooter>
        <Button variant="primary" disabled={companies.length > 1 && !company} onClick={nextStep}>
          <div className="flex items-center gap-x-2">
            <span>Next step</span>
            <Icon type="arrowRight" className="text-white" />
          </div>
        </Button>
      </NavStepperFooter>
    </NavStepperCard>
  )
}
