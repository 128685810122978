import React, { useMemo } from 'react'
import {
  Button,
  Icon,
  NavStepperCard,
  NavStepperContent,
  NavStepperFooter,
  useNavStepper,
} from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { useCompany } from 'hooks/useCompany'
import { CompanyInfoForm } from './CompanyInfoForm'

export function CompanyInformation() {
  const { company } = useCompany()
  const { nextStep, previousStep } = useNavStepper()

  const isNextDisabled = useMemo(() => {
    if (isEmpty(company)) {
      return true
    }
    const { name, street, city, postCode, country, registrationNumber, companySize } = company

    return (
      isEmpty(name) ||
      isEmpty(street) ||
      isEmpty(city) ||
      isEmpty(postCode) ||
      isEmpty(country) ||
      isEmpty(companySize) ||
      isEmpty(registrationNumber)
    )
  }, [company])

  return (
    <NavStepperCard className="max-w-[680px] w-full">
      <NavStepperContent className="px-6 py-10 h-10 flex flex-col">
        <form
          className="flex flex-row justify-start gap-6 flex-wrap"
          autoComplete="off"
          id="company-contact-form"
          onSubmit={nextStep}
        >
          <CompanyInfoForm />
        </form>
      </NavStepperContent>
      <NavStepperFooter>
        <Button iconType="arrowLeft" variant="secondary" onClick={previousStep}>
          Previous step
        </Button>
        <Button
          form="company-contact-form"
          type="submit"
          variant="primary"
          onClick={nextStep}
          disabled={isNextDisabled}
        >
          <div className="flex items-center gap-x-2">
            <span>Next step</span>
            <Icon
              type="arrowRight"
              className={isNextDisabled ? 'text-gray-900/20' : 'text-white'}
            />
          </div>
        </Button>
      </NavStepperFooter>
    </NavStepperCard>
  )
}
