import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { IContact, IInvite } from 'types'
import { API_URLS } from 'constants/apiUrls'
import { resolveUrl, resolveApiUrl } from 'utils/resolveUrl'
import useApi from 'hooks/useApi'
import { useInviteToken } from 'hooks/useInviteToken'
import { ERROR_TYPE } from 'constants/'
import { ROUTE_URLS } from 'constants/routeUrls'

interface IAppData {
  createAgree: boolean
}

const APP_DATA: IAppData = { createAgree: false }

export interface IInviteDataContext {
  appData: IAppData
  contact: IContact
  invite: IInvite
  loading: boolean
  reset: () => void
  updateAppData: (changes: Partial<IAppData>) => void
  updateContact: (contactChanges: Partial<IContact>) => void
}

export const InviteDataContext = createContext<IInviteDataContext>({
  appData: APP_DATA,
  contact: {},
  invite: {},
  loading: false,
  reset: () => null,
  updateAppData: () => null,
  updateContact: () => null,
})

interface IInviteDataContextProviderProps {
  children: ReactNode
}

export function InviteDataContextProvider({ children }: IInviteDataContextProviderProps) {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [appData, setAppData] = useState<IAppData>(APP_DATA)
  const [contact, setContact] = useState<IContact>({})
  const [invite, setInvite] = useState<IInvite>({})

  const token = useInviteToken()
  const isOnboarding = pathname.includes(ROUTE_URLS.ON_BOARDING)
  const { data: userDetails, loading: userLoading } = useApi<IContact>(API_URLS.USER)

  // Invite api call
  const inviteApiUrl = isEmpty(token)
    ? null
    : resolveApiUrl(API_URLS.INVITE_BY_TOKEN, {}, { token })
  const {
    data: inviteData,
    error: inviteError,
    loading: inviteLoading,
  } = useApi<IInvite>(inviteApiUrl)

  const updateAppData = useCallback((changes: Partial<IAppData>) => {
    setAppData((prev: IAppData) => {
      return { ...prev, ...changes }
    })
  }, [])

  const updateContact = useCallback((contactChanges: Partial<IContact>) => {
    setContact((prev: IContact) => {
      return { ...prev, ...contactChanges }
    })
  }, [])

  // no token error
  useEffect(() => {
    if (isEmpty(token) && isOnboarding) {
      navigate(resolveUrl(ROUTE_URLS.ERROR, { code: ERROR_TYPE.NO_TOKEN }))
    }
  }, [token, isOnboarding])

  // set invite data
  useEffect(() => {
    if (inviteError && isOnboarding) {
      navigate(
        resolveUrl(
          ROUTE_URLS.ERROR,
          {
            code: inviteError?.data?.code || ERROR_TYPE.INVALID_TOKEN,
          },
          { traceId: inviteError?.data?.traceId }
        )
      )
    } else if (!isEmpty(inviteData) && !userLoading) {
      const contactData = inviteData.contact as IContact
      setInvite(inviteData)
      setContact({ ...contactData, ...userDetails })
    }
  }, [inviteData, inviteError, isOnboarding, userLoading, userDetails])

  const reset = useCallback(() => {
    setAppData(APP_DATA)
    setContact({})
    setInvite({})
  }, [])

  const providerValue = useMemo(() => {
    return {
      appData,
      contact,
      invite,
      loading: inviteLoading,
      reset,
      updateAppData,
      updateContact,
    }
  }, [appData, contact, invite, inviteLoading, reset, updateAppData, updateContact])

  return <InviteDataContext.Provider value={providerValue}>{children}</InviteDataContext.Provider>
}
