import { useMemo } from 'react'
import sortBy from 'lodash/fp/sortBy'
import isEmpty from 'lodash/fp/isEmpty'
import { useAuth0 } from '@auth0/auth0-react'
import { ICompany } from 'types'
import { API_URLS } from 'constants/apiUrls'
import useApi from './useApi'

export function useCompanies() {
  const { isAuthenticated } = useAuth0()
  const apiUrl = isAuthenticated ? API_URLS.ALL_COMPANIES : null
  const { data, error, loading } = useApi<ICompany[]>(apiUrl)

  const companies = useMemo(() => (!isEmpty(data) ? sortBy('name', data) : []), [data])

  return {
    companies,
    error,
    isLoading: loading || isEmpty(apiUrl),
  }
}
