export const LEADS = [
  {
    id: 0,
    label: 'General Enquiry',
    value: 'GeneralEnquiry',
  },
  {
    id: 1,
    label: 'Company Referral',
    value: 'CompanyReferral',
  },
]

export const COMPANY_TYPES = [
  {
    id: 0,
    label: 'Brand Owner',
    value: 'BrandOwner',
  },
  {
    id: 1,
    label: 'Processor/Manufacturer',
    value: 'ProcessorOrManufacturer',
  },
  {
    id: 2,
    label: 'Retailer',
    value: 'Retailer',
  },
  {
    id: 3,
    label: 'Farmer/Grower',
    value: 'FarmerOrGrower',
  },
  {
    id: 4,
    label: 'Agent/Source/Broker',
    value: 'AgentOrSourceOrBroker',
  },
  {
    id: 5,
    label: 'Distributor',
    value: 'Distributor',
  },
  {
    id: 6,
    label: 'Packaging Services',
    value: 'PackagingServices',
  },
  {
    id: 7,
    label: 'Storage Services',
    value: 'StorageServices',
  },
]

export const COMPANY_SIZE_OPTIONS = [
  {
    id: 0,
    label: 'Up to 50',
    value: 'UpTo50',
  },
  {
    id: 1,
    label: '50 – 100',
    value: 'From50Till100',
  },
  {
    id: 2,
    label: '100 – 500',
    value: 'From100Till500',
  },
  {
    id: 3,
    label: '500 – 1000',
    value: 'From500Till1000',
  },
  {
    id: 4,
    label: 'Above 1000',
    value: 'Above1000',
  },
]
