/* eslint-disable */
import { useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import isEmpty from 'lodash/fp/isEmpty'
import { AUTH0_SCOPE } from 'constants/'
import { useRetryAsync } from './useRetryAsync'

const MAX_RETRIES = 2

export function useToken() {
  const { getAccessTokenSilently, user } = useAuth0()
  const acquireTokenWithRetry = useRetryAsync(getAccessTokenSilently, MAX_RETRIES)

  const acquireToken = useCallback(
    async (companyId?: string) => {
      const scope = !isEmpty(companyId) ? AUTH0_SCOPE.concat(' ', `c_${companyId}`) : AUTH0_SCOPE
      try {
        const token = await acquireTokenWithRetry({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope,
          },
        })
        if (token) {
          return token
        }
        throw { error: 'consent_required' }
      } catch (error) {
        console.error(error)
      }
    },
    [getAccessTokenSilently]
  )

  return {
    acquireToken,
    user,
  }
}
