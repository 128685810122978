import React from 'react'
import { Button } from '@mondra/ui-components'
import { GET_SUPPORT } from 'constants/'
import { AdditionalPageLayout } from './AdditionalPageLayout'

export function PageNotFound() {
  return (
    <AdditionalPageLayout
      status="404"
      title="Uh oh! I think you're lost."
      description="It looks like the page you are looking for doesn't exist"
      action={
        <a href={GET_SUPPORT} target="_blank" rel="noopener noreferrer">
          <Button variant="primary">Contact</Button>
        </a>
      }
    />
  )
}
