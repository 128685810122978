import React, { useCallback } from 'react'
import { Dropdown, Input } from '@mondra/ui-components'
import find from 'lodash/fp/find'
import FormRow, { FormLabel } from 'components/row/FormRow'
import { useInviteData } from 'hooks/useInviteData'
import { LEADS } from 'constants/options'
import { SectionTitle } from 'components/SectionTitle'
import { useCompany } from 'hooks/useCompany'

export function PersonalProfile() {
  const { contact, updateContact } = useInviteData()
  const { companies } = useCompany()

  const handleContactChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      updateContact({ [name]: value })
    },
    [updateContact]
  )

  const handleLeadChange = useCallback(
    selected => {
      updateContact({ leadSource: selected.value })
    },
    [updateContact]
  )

  return (
    <div className="flex flex-col justify-start flex-1">
      <SectionTitle
        title="About you"
        description="To help us improve your Mondra experience please confirm or edit the information below:"
      />
      <div className="mt-6 flex flex-col gap-y-4">
        <FormRow className="flex-row flex-wrap gap-x-4 gap-y-4">
          <div className="flex-grow basis-0">
            <FormLabel>First name</FormLabel>
            <Input
              className="!bg-coolGray-50"
              readOnly
              required
              name="firstName"
              type="text"
              value={contact.firstName || ''}
            />
          </div>
          <div className="flex-grow basis-0">
            <FormLabel>Last name</FormLabel>
            <Input
              className="!bg-coolGray-50"
              readOnly
              required
              name="lastName"
              type="text"
              value={contact.lastName || ''}
            />
          </div>
        </FormRow>
        <FormRow>
          <FormLabel>Position</FormLabel>
          <Input
            required
            name="position"
            type="text"
            onChange={handleContactChange}
            value={contact?.position || ''}
          />
        </FormRow>
        <FormRow>
          <FormLabel className="!flex justify-between leading-6">
            <div>Contact telephone number</div>
            <div>Optional</div>
          </FormLabel>
          <Input
            required
            name="phone"
            type="number"
            onChange={handleContactChange}
            value={contact?.phone || ''}
          />
        </FormRow>
        {companies.length === 0 && (
          <FormRow>
            <FormLabel className="!flex justify-between leading-6">
              <div>Where did you hear about us?</div>
              <div>Optional</div>
            </FormLabel>
            <Dropdown
              selected={find({ value: contact?.leadSource }, LEADS)}
              onChange={handleLeadChange}
              options={LEADS}
              placeholder="Select an option"
            />
          </FormRow>
        )}
      </div>
    </div>
  )
}
