export const API_URLS = {
  ALL_COMPANIES: `${process.env.REACT_APP_IDENTITY_API_URL}/api/v1/me/companies`,
  COUNTRIES: `${process.env.REACT_APP_IDENTITY_API_URL}/api/v1/countries`,
  CREATE_LEGAL_ENTITY: `${process.env.REACT_APP_IDENTITY_API_URL}/api/v2/signup`,
  INVITE_ACCEPT: '/api/v1/company/:companyId/retailer-invitation/accept',
  INVITE_BY_TOKEN: '/api/v1/retailer-invitation',
  INVITE_REJECT: '/api/v1/retailer-invitation/reject',
  USER: `${process.env.REACT_APP_IDENTITY_API_URL}/api/v1/me/profile`,
  VERIFY_USER_BY_TOKEN: '/api/v1/public/retailer-invitation',
}
