import memoize from 'lodash/fp/memoize'
import map from 'lodash/fp/map'
import { API_URLS } from 'constants/apiUrls'
import useApi from './useApi'

const parse = memoize(
  map((c: string) => ({
    label: c,
    value: c,
  }))
)

export default function useCountries() {
  const { data = [], loading } = useApi(API_URLS.COUNTRIES)

  return {
    countries: parse(data),
    loading,
  }
}
