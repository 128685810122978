import React, { useEffect } from 'react'
import { Button } from '@mondra/ui-components'
import { AdditionalPageLayout } from './AdditionalPageLayout'

export function CompanySuccess() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace(`${process.env.REACT_APP_PROCESSOR_SAAS_URL}`)
    }, 5000)
  }, [])

  const handleDashboardClick = () => {
    window.location.replace(`${process.env.REACT_APP_PROCESSOR_SAAS_URL}`)
  }

  return (
    <AdditionalPageLayout
      status="All Done!"
      title="Company created successfully"
      description={
        <>
          <div className="mb-1">
            We are happy to have you onboard. Please wait while we are preparing your data.
          </div>
          <div>You will be redirected to your Company Dashboard in a moment.</div>
        </>
      }
      action={
        <Button iconType="chartTreemap" onClick={handleDashboardClick} variant="primary">
          Company dashboard
        </Button>
      }
    />
  )
}
