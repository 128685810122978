/* eslint-disable @typescript-eslint/no-explicit-any */
import { useToastNotification } from '@mondra/ui-components'
import { useEffect, useMemo, useState } from 'react'
import { parseApiError } from 'utils'
import { TOAST_POSITION } from 'constants/'
import { useToken } from './useToken'

interface IResError {
  data: any
  status: number
  statusText: string
}

export default function useApi<T = any>(
  url?: string | null,
  companyId?: string
): { data?: T; error?: IResError; loading: boolean } {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<T>()
  const [error, setError] = useState<IResError>()
  const { acquireToken } = useToken()
  const { showError } = useToastNotification(TOAST_POSITION)

  useEffect(() => {
    if (!url) {
      setLoading(false)
      return
    }
    setLoading(true)
    acquireToken(companyId).then(token => {
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-MONDRA-APP': 'Identity',
        },
      })
        .then(async rsp => {
          if (rsp.ok) {
            return rsp.json()
          }
          const info = await rsp.json()

          if (rsp.status !== 404) {
            showError(
              {
                ...parseApiError(info),
              },
              { duration: 5000, id: info.traceId! }
            )
          }

          setError({ data: info, status: rsp.status, statusText: rsp.statusText })
          return undefined
        })
        .then(payload => {
          setData(payload)
        })
        .catch(err => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }, [companyId, url, acquireToken])

  return useMemo(() => ({ data, error, loading }), [data, error, loading])
}
