import React, { useEffect } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { useAuth0 } from '@auth0/auth0-react'
import { Toaster } from '@mondra/ui-components'
import { ROUTE_URLS } from 'constants/routeUrls'
import { sanatizeParams } from 'utils/queryString'
import { useInviteToken } from 'hooks/useInviteToken'
import { Onboarding } from 'pages/Onboarding'
import { CompanySuccess } from 'pages/CompanySuccess'
import { PageNotFound } from 'pages/PageNotFound'
import Layout from 'pages/Layout'
import { ErrorPage } from 'pages/ErrorPage'
import { RejectPage } from 'pages/RejectPage'
import { PendoTracker } from 'components/PendoTracker'
import { resolveApiUrl } from 'utils/resolveUrl'
import { API_URLS } from 'constants/apiUrls'
import CompanyContextProvider from 'context/CompanyContextProvider'

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const location = useLocation()
  const inviteToken = useInviteToken()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      fetch(resolveApiUrl(API_URLS.VERIFY_USER_BY_TOKEN, {}, { token: `${inviteToken}` }))
        .then(res => {
          if (res.ok) {
            return res.json()
          }
          return undefined
        })
        .then(data => {
          const additionalParams =
            inviteToken && data?.canLogin === false ? { inviteToken, screen_hint: 'signup' } : {}

          loginWithRedirect({
            appState: {
              appQueryParams: sanatizeParams(location.search),
              returnTo: window.location.pathname,
            },
            authorizationParams: {
              ...additionalParams,
            },
          })
        })
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, inviteToken, location.search])

  if (!isAuthenticated) {
    return null
  }

  return (
    <SWRConfig
      value={{
        fetcher: (resource, init) => fetch(resource, init).then(res => res.json()),
      }}
    >
      <CompanyContextProvider isAuthenticating={isLoading || !isAuthenticated}>
        <Toaster />
        <PendoTracker />
        <Routes>
          <Route path={ROUTE_URLS.BASE} element={<Layout />}>
            <Route
              index
              element={<Navigate to={`${ROUTE_URLS.ON_BOARDING}${location.search}`} />}
            />
            <Route path={`${ROUTE_URLS.ON_BOARDING}/*`} element={<Onboarding />} />
            <Route path={ROUTE_URLS.SUCCESS} element={<CompanySuccess />} />
            <Route path={ROUTE_URLS.REJECT} element={<RejectPage />} />
            <Route path={ROUTE_URLS.ERROR} element={<ErrorPage />} />
            <Route path={ROUTE_URLS.PAGE_NOT_FOUND} element={<PageNotFound />} />
          </Route>
        </Routes>
      </CompanyContextProvider>
    </SWRConfig>
  )
}

export default App
