/* eslint-disable */
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IChildrenProps } from '@mondra/ui-components/@types'
import { useAuth0 } from '@auth0/auth0-react'

declare global {
  interface Window {
    pendo: {
      initialize: (visitor: IPendoInitConfig) => void
      identify: (visitor: IPendoInitConfig) => void
      updateOptions: (IPageConfig) => void
    }
  }
}

interface IPageConfig {
  path: string
}

interface IPendoInitConfig {
  visitor: { id?: string }
  account: {
    id: string
		inviteToken: string | null
  }
}

export function PendoTracker({ children }: IChildrenProps) {
  const { user } = useAuth0()
	const [searchParams, _] = useSearchParams()
  useEffect(() => {
    if (user?.sub) {
      window.pendo.identify({
        visitor: { id: user?.sub },
        account: {
          id: 'SAAS-Onboarding',
					inviteToken: searchParams.get('inviteToken')
        },
      })
    }
  }, [user?.sub])

  return children
}
