import { METHOD_POST } from 'constants/'
import { API_URLS } from 'constants/apiUrls'
import { IApiError, IContact, ICompany } from 'types'
import { useUpdateApi } from './useUpdateApi'

interface IUseCreateCompany {
  contact: IContact
  company?: ICompany
  onError?: (error: IApiError) => void
  onSuccess: (CompanyData: ICompany) => void
}

export function useCreateCompany({ contact, company, onError, onSuccess }: IUseCreateCompany) {
  const onCompanyCreated = (response: { data: ICompany }) => {
    if (response && response.data && response.data.id) {
      onSuccess(response.data)
    }
  }

  const { update: createCompany, saving } = useUpdateApi({
    method: METHOD_POST,
    onError,
    onUpdate: onCompanyCreated,
    url: API_URLS.CREATE_LEGAL_ENTITY,
  })

  const handleCreateCompany = () => {
    if (!company) {
      return
    }
    const payload = {
      company: {
        companyCity: company.city,
        companyCountry: company.country,
        companyName: company.name,
        companyNumber: company.registrationNumber,
        companyPostalCode: company.postCode,
        companySize: company.companySize,
        companyState: company.state,
        companyStreetAddress: company.street,
        companyType: company.type,
      },
      contactPhoneNumber: contact.phone,
      emailAddress: contact.emailAddress,
      firstName: contact.firstName,
      lastName: contact.lastName,
      leadSource: contact.leadSource,
      position: contact.position,
    }
    createCompany({ payload })
  }

  return {
    createCompany: handleCreateCompany,
    saving,
  }
}
