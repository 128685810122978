import React from 'react'
import { IApiError } from 'types'
import { ErrorBlock } from 'components/ErrorBlock'

export function isNullify(value?: string | number | null) {
  return value === '' || value === null || value === undefined
}

export function parseApiError({ detail, errors, message, title, traceId }: IApiError) {
  return {
    children: <ErrorBlock errors={errors ?? {}} message={detail} traceId={traceId} />,
    description: '',
    label: message || title || 'Error',
  }
}

export function parseTemplate(template: string, replacer: Record<string, string | number>) {
  return Object.keys(replacer).reduce((previous: string, key: string) => {
    return previous.replaceAll(`{{${key}}}`, replacer[key].toString())
  }, template)
}
