import React, { useMemo } from 'react'
import {
  Button,
  Icon,
  NavStepperCard,
  NavStepperContent,
  NavStepperFooter,
  useNavStepper,
} from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { useInviteData } from 'hooks/useInviteData'
import { PersonalProfile } from './PersonalProfile'

export function AboutInformation() {
  const { contact } = useInviteData()
  const { nextStep, previousStep } = useNavStepper()

  const isNextDisabled = useMemo(() => {
    const { firstName, lastName, position } = contact || {}

    return isEmpty(firstName) || isEmpty(lastName) || isEmpty(position)
  }, [contact])

  return (
    <NavStepperCard className="max-w-[1400px] w-full">
      <NavStepperContent className="mx-auto py-10 px-4 h-10 flex flex-col max-w-[680px]">
        <form
          className="p-6 bg-coolGray-50 rounded-md"
          autoComplete="off"
          id="company-contact-form"
          onSubmit={nextStep}
        >
          <PersonalProfile />
        </form>
      </NavStepperContent>
      <NavStepperFooter>
        <Button iconType="arrowLeft" variant="secondary" onClick={previousStep}>
          Previous step
        </Button>
        <Button
          form="company-contact-form"
          type="submit"
          variant="primary"
          onClick={nextStep}
          disabled={isNextDisabled}
        >
          <div className="flex items-center gap-x-2">
            <span>Next step</span>
            <Icon
              type="arrowRight"
              className={isNextDisabled ? 'text-gray-900/20' : 'text-white'}
            />
          </div>
        </Button>
      </NavStepperFooter>
    </NavStepperCard>
  )
}
