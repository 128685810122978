import React from 'react'
import { Button, Input } from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { IEvent, ISupplier } from 'types'

interface ISupplierListItemProps {
  onRejectMsgChange: (event: IEvent) => void
  onRejectToggle: () => void
  supplier: ISupplier
}

export function SupplierListItem({
  supplier,
  onRejectMsgChange,
  onRejectToggle,
}: ISupplierListItemProps) {
  return (
    <li className="flex items-start justify-between gap-x-4 p-4">
      <div className="flex-grow" title={supplier.name}>
        <div className="flex justify-between items-center">
          <div className="text-sm truncate py-2">{supplier.name}</div>
          {!supplier.isReject && (
            <Button variant="ghost" size="md" onClick={onRejectToggle}>
              Reject
            </Button>
          )}
        </div>
        {supplier.isReject && (
          <div className="flex justify-between items-center mt-2">
            <Input
              className="max-w-lg"
              placeholder="Enter rejection reason"
              value={supplier.rejectionReason || ''}
              invalid={isEmpty(supplier.rejectionReason)}
              onChange={onRejectMsgChange}
            />
            <Button
              className="ml-2"
              variant="ghost"
              isOnlyIcon
              iconType="close"
              size="lg"
              onClick={onRejectToggle}
            />
          </div>
        )}
      </div>
    </li>
  )
}
