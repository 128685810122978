import React from 'react'
import { IClassNameProps } from '@mondra/ui-components/@types'

interface ISectionTitleProps extends IClassNameProps {
  description?: React.ReactNode
  title: string
}

export function SectionTitle({ className, description, title }: ISectionTitleProps) {
  return (
    <div className={className}>
      <div className="text-lg font-medium text-coolGray-900">{title}</div>
      {description && (
        <div className="mt-2 text-sm font-normal leading-5 text-coolGray-700">{description}</div>
      )}
    </div>
  )
}
