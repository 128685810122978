import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { AUTH0_SCOPE } from 'constants/'

interface IAuth0ProviderWithHistoryProps {
  children: React.ReactNode
}

function Auth0ProviderWithHistory({ children }: IAuth0ProviderWithHistoryProps) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE

  const navigate = useNavigate()

  const onRedirectCallback = appState => {
    navigate(`${appState?.returnTo || window.location.pathname}?${appState?.appQueryParams || ''}`)
  }
  return (
    <Auth0Provider
      domain={domain || ''}
      clientId={clientId || ''}
      authorizationParams={{
        audience,
        redirect_uri: `${window.location.origin}/`,
        scope: AUTH0_SCOPE,
      }}
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
