import React, { useLayoutEffect } from 'react'
import { Button, Tooltip } from '@mondra/ui-components'

declare global {
  interface Window {
    ZohoHCAsap: {
      Action: (action: string) => void
    }
    ZohoHCAsapSettings: {}
  }
}

window.ZohoHCAsapSettings = {
  hideLauncherIcon: true,
}

export function Help() {
  useLayoutEffect(() => {
    const zohoScript = document.querySelector('[aria-label="zoho-portal"]')

    if (!zohoScript) {
      const d = document
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.defer = true
      s.ariaLabel = 'zoho-portal'
      s.src = 'https://desk.zoho.eu/portal/api/web/inapp/64926000000411001?orgId=20075559398'
      d.getElementsByTagName('head')[0].appendChild(s)
    }
  }, [])

  const handleOpen = () => {
    if (window.ZohoHCAsap && window.ZohoHCAsap.Action) {
      window.ZohoHCAsap.Action('open')
    }
  }

  return (
    <Tooltip interactive content="How can we help?">
      <Button
        variant="white"
        iconType="statusHelp"
        isOnlyIcon
        size="lg"
        onClick={handleOpen}
        className="max-h-14 w-14 p-4.5"
      />
    </Tooltip>
  )
}
