import React from 'react'
import { Header } from '@mondra/ui-components'
import { Outlet } from 'react-router-dom'
import { Help } from 'components/help/Help'
import { UserAvatar } from 'components/user-avatar/UserAvatar'
import { InviteDataContextProvider } from 'context/InviteDataContextProvider'

export default function Layout() {
  return (
    <div className="layout flex min-h-screen flex-col">
      <Header
        className="dark"
        appName="Onboarding"
        gapClass=""
        right={
          <div className="flex h-full items-center justify-between space-x-4">
            <Help />
            <UserAvatar />
          </div>
        }
      />
      <div className="flex h-full flex-grow flex-col bg-gray-50">
        <InviteDataContextProvider>
          <Outlet />
        </InviteDataContextProvider>
      </div>
    </div>
  )
}
