import React from 'react'
import { Button, Icon } from '@mondra/ui-components'
import { useLocation } from 'react-router-dom'
import { GET_SUPPORT } from 'constants/'

export function RejectPage() {
  const { state } = useLocation()

  return (
    <div className="border border-gray-300 mt-10 mx-auto w-[600px] rounded bg-white">
      <div className=" px-6 py-10">
        <div className="text-lg text-coolGray-900 font-medium mb-2">Invitation rejected</div>
        <div className="text-sm leading-5 text-coolGray-700">
          You have rejected the invitation to join Mondra due to incorrect alias information being
          supplied.
          <br />
          <br />
          We will forward your feedback to {state?.retailerName || 'retailer'}, ask them to fix any
          issues and resubmit the invitation to you.
          <br />
          <br />
          If you have any queries please contact:{' '}
          <a
            href={GET_SUPPORT}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-600 font-medium"
          >
            support@mondra.com
          </a>
        </div>
      </div>
      <div className="border-t border-gray-300 p-6 flex justify-end">
        <a href="https://www.mondra.com" rel="noopener noreferrer">
          <Button variant="secondary" size="md">
            <div className="flex items-center gap-x-2">
              <span>Go to mondra.com</span>
              <Icon type="arrowRight" className="text-primary-600" />
            </div>
          </Button>
        </a>
      </div>
    </div>
  )
}
