import React from 'react'
import { Button } from '@mondra/ui-components'
import { useParams, useSearchParams } from 'react-router-dom'
import toUpper from 'lodash/fp/toUpper'
import { ERROR_TYPE, GET_SUPPORT } from 'constants/'
import { AdditionalPageLayout } from './AdditionalPageLayout'

const ERROR_MSG = {
  [ERROR_TYPE.INVALID_OPERATION]: {
    msg: 'Invalid operation',
    status: 'INVALID OPERATION',
  },
  [ERROR_TYPE.INVALID_TOKEN]: {
    msg: 'It seems this invitation is not valid.',
    status: 'INVALID INVITE',
  },
  [ERROR_TYPE.NO_TOKEN]: {
    msg: 'It seems you do not have invitation.',
    status: 'NO TOKEN',
  },
  [ERROR_TYPE.EXPIRED_TOKEN]: {
    msg: 'This invitation is expired.',
    status: 'INVITATION EXPIRED',
  },
  [ERROR_TYPE.INVALID_INVITATION_STATUS]: {
    msg: 'This invitation is already responded and closed now.',
    status: 'INVITATION CLOSED',
  },
  [ERROR_TYPE.NOT_FOUND]: {
    msg: 'There is an error.',
    status: 'ERROR',
  },
}

const defaultErrorType = ERROR_TYPE.NOT_FOUND

export function ErrorPage() {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const errorType = params.code || defaultErrorType
  const traceId = searchParams.get('traceId')

  const error = ERROR_MSG[toUpper(errorType)] || ERROR_MSG[defaultErrorType]

  return (
    <AdditionalPageLayout
      status="Error"
      title={error.status}
      description={
        <div className="space-y-2">
          <div>{error.msg} Please contact administrator for further details.</div>
          {!!traceId && <div className="font-bold">Trace Id: {traceId}</div>}
        </div>
      }
      action={
        <a href={GET_SUPPORT} target="_blank" rel="noopener noreferrer">
          <Button variant="primary">Contact</Button>
        </a>
      }
    />
  )
}
