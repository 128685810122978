import isEmpty from 'lodash/fp/isEmpty'
import { resolveApiUrl } from 'utils/resolveUrl'
import { METHOD_POST } from 'constants/'
import { API_URLS } from 'constants/apiUrls'
import { IApiError } from 'types'
import { useInviteToken } from './useInviteToken'
import { useUpdateApi } from './useUpdateApi'
import { useCompany } from './useCompany'

interface IUseAcceptInvite {
  onError?: (error: IApiError) => void
  onSuccess: () => void
}

export function useAcceptInvite({ onError, onSuccess }: IUseAcceptInvite) {
  const token = useInviteToken()
  const { companyId } = useCompany()

  const { update: acceptInvite, saving } = useUpdateApi({
    companyId,
    method: METHOD_POST,
    onError,
    onUpdate: onSuccess,
    url: !isEmpty(companyId)
      ? resolveApiUrl(API_URLS.INVITE_ACCEPT, { companyId }, { token })
      : null,
  })

  return {
    acceptInvite,
    saving,
  }
}
