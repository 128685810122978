import { IUseToastNotification } from '@mondra/ui-components'

export const AUTH0_SCOPE = 'user profile email'
export const METHOD_POST = 'POST'
export const METHOD_PUT = 'PUT'
export const COMPANY_NUMBER_MAX_LENGTH = 8
export const GET_SUPPORT = 'https://support.mondra.com/portal/en/newticket'
export const SUPPORT_MAIL = 'ops@mondra.com'
export const ERROR_TYPE = {
  COMPANY_DUPLICATE: 'COMPANY_DUPLICATE',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  INVALID_INVITATION_STATUS: 'INVALID_INVITATION_STATUS',
  INVALID_OPERATION: 'INVALID_OPERATION',
  INVALID_PARAMETER: 'INVALID_PARAMETER',
  INVALID_TOKEN: 'INVALID_TOKEN',
  NO_TOKEN: 'NO_TOKEN',
  NOT_FOUND: 'NOT_FOUND',
}
export const TOAST_POSITION: IUseToastNotification = {
  position: 'top-center',
}

export const USER_CLAIMS = {
  EMAIL: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
  FIRST_NAME: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
  FULL_NAME: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  LAST_NAME: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
}
