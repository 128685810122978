import React, { useState } from 'react'
import {
  AlertDialog,
  Button,
  Checkbox,
  Dialog,
  NavStepperCard,
  NavStepperContent,
  NavStepperFooter,
  Notification,
  useNavStepper,
  useToastNotification,
} from '@mondra/ui-components'
import Lottie from 'lottie-react'
import { useUpdateApi } from 'hooks/useUpdateApi'
import { useInviteData } from 'hooks/useInviteData'
import { useAcceptInvite } from 'hooks/useAcceptInvite'
import { useCreateCompany } from 'hooks/useCreateCompany'
import { IApiError, ICompany, IEvent } from 'types'
import { parseApiError } from 'utils'
import { SectionTitle } from 'components/SectionTitle'
import { useCompany } from 'hooks/useCompany'
import { ERROR_TYPE, METHOD_PUT, TOAST_POSITION } from 'constants/'
import { resolveApiUrl } from 'utils/resolveUrl'
import { API_URLS } from 'constants/apiUrls'
import lottieSparkle from 'assets/lottie-sparkle.json'
import { CompanyExistDialog } from './CompanyExistDialog'

export function CreateCompany() {
  const [agreeAlert, setAgreeAlert] = useState(false)
  const [successDialog, setSuccessDialog] = useState(false)
  const [companyErrorDialog, setCompanyErrorDialog] = useState(false)

  const { previousStep } = useNavStepper()
  const { showError } = useToastNotification(TOAST_POSITION)
  const { company, updateCompany } = useCompany()
  const {
    appData: { createAgree },
    contact,
    invite: { suppliers = [], token },
    updateAppData,
  } = useInviteData()

  const onInviteAccepted = () => {
    setSuccessDialog(true)
  }

  const onError = (error: IApiError) => {
    showError(
      {
        ...parseApiError(error),
      },
      { duration: 5000, id: error.traceId! }
    )
  }

  const { acceptInvite, saving: accepting } = useAcceptInvite({
    onError,
    onSuccess: onInviteAccepted,
  })

  const onCompanyCreated = (newCompany: ICompany) => {
    updateCompany(newCompany)
    const acceptApiUrl = resolveApiUrl(
      API_URLS.INVITE_ACCEPT,
      { companyId: newCompany?.id },
      { token }
    )
    acceptInvite({ companyId: newCompany?.id, url: acceptApiUrl })
  }

  const onCompanyCreateError = (error: IApiError) => {
    if (error?.code === ERROR_TYPE.COMPANY_DUPLICATE) {
      setCompanyErrorDialog(true)
    } else {
      onError(error)
      previousStep()
    }
  }

  const { createCompany, saving: creatingCompany } = useCreateCompany({
    company,
    contact,
    onError: onCompanyCreateError,
    onSuccess: onCompanyCreated,
  })

  const { update: updateUser, saving: updatingUser } = useUpdateApi({
    companyId: company?.id,
    method: METHOD_PUT,
    onError,
    onUpdate: () => acceptInvite({ companyId: company?.id }),
    url: API_URLS.USER,
  })

  const handleAgreeChecked = (event: IEvent) => updateAppData({ createAgree: event.target.checked })

  const handleCreateCompany = () => {
    if (!createAgree) {
      setAgreeAlert(true)
      return
    }
    if (company?.id) {
      const { firstName, lastName, phone, position } = contact
      if (position || phone) {
        updateUser({
          companyId: company.id,
          payload: {
            firstName,
            lastName,
            phone: phone || undefined,
            position: position || undefined,
          },
        })
      } else {
        acceptInvite({ companyId: company?.id })
      }
    } else {
      createCompany()
    }
  }

  const gotoDashboard = () => {
    if (process.env.REACT_APP_PROCESSOR_SAAS_URL) {
      window.location.href = process.env.REACT_APP_PROCESSOR_SAAS_URL
    }
  }

  const saving = accepting || creatingCompany || updatingUser
  const disabled = saving || successDialog

  return (
    <NavStepperCard className="max-w-[1400px] w-full max-h-screen-c-nav">
      <NavStepperContent className="flex flex-col px-6 py-10 gap-y-4">
        <div className="flex flex-col-reverse lg:flex-row flex-grow gap-6">
          <div className="flex flex-col flex-1">
            <SectionTitle className="mb-3" title="Your company" />
            <div className="text-coolGray-700 mb-6 flex flex-col gap-y-4">
              <div className="flex">
                <div className="max-w-[240px] w-full font-medium">Registered company name</div>
                <div className="font-normal">{company?.name}</div>
              </div>
              <div className="flex">
                <div className="max-w-[240px] w-full font-medium">Registration number</div>
                <div className="font-normal">{company?.registrationNumber}</div>
              </div>
            </div>
            <Notification type="warning" label="You are about to receive sensitive data">
              <div className="leading-6">
                Based on the aliases listed here, your retailer contact is giving you access to
                sensitive environmental and sales/purchase volume data on all the products you
                supply to them. It is your responsibility to check that the aliases you trade under
                are correct.
              </div>
            </Notification>
          </div>
          <div className="flex flex-col flex-1">
            <SectionTitle className="mb-3" title="Your aliases" />
            <div className="flex flex-col gap-y-4">
              {suppliers.map(supplier => (
                <div key={supplier.id} className="text-sm font-medium text-coolGray-900">
                  {supplier.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <AlertDialog title="Alert" open={agreeAlert} onClose={() => setAgreeAlert(false)}>
          Please agree and accept the terms & conditions before proceeding.
        </AlertDialog>
        <Dialog
          hideCloseBtn
          className="rounded-lg md:max-w-[48rem]"
          open={successDialog}
          onClose={() => null}
        >
          <Dialog.Description className="relative">
            <Lottie
              className="absolute -top-28 left-28 z-0 opacity-50"
              loop
              animationData={lottieSparkle}
            />
            <div className="flex flex-col flex-grow justify-center items-center gap-y-3 p-10 text-base text-coolGray-700 text-center">
              <div className="text-3xl text-gray-900 font-semibold mb-10">Congratulations</div>
              <div>We are happy to have you on board!</div>
              <div>
                Your data is currently being prepared and synchronised.{' '}
                <b>This may take up to one hour</b>.
              </div>
              <div>Access your dashboard to check on the status of your data.</div>
              <div className="text-coolGray-200 text-sm z-10 mt-10">
                <Button variant="primary" onClick={gotoDashboard}>
                  Go to my dashboard
                </Button>
              </div>
            </div>
          </Dialog.Description>
        </Dialog>
        <CompanyExistDialog
          contact={contact}
          open={companyErrorDialog}
          onClose={() => setCompanyErrorDialog(false)}
          token={token}
        />
      </NavStepperContent>
      <NavStepperFooter className="justify-between">
        <Checkbox
          label={
            <div>
              The information I have supplied is correct and I accept the{' '}
              <a
                href={process.env.REACT_APP_TERMS_CREATE_COMPANY}
                target="_blank"
                rel="noreferrer"
                className="text-primary-500"
              >
                Terms & Conditions
              </a>
            </div>
          }
          checked={createAgree}
          disabled={disabled}
          onChange={handleAgreeChecked}
        />
        <div className="flex gap-x-4">
          <Button
            iconType="arrowLeft"
            variant="secondary"
            disabled={disabled}
            onClick={previousStep}
          >
            Previous step
          </Button>
          <Button
            iconClass={saving ? 'animate-spin' : undefined}
            iconType={saving ? 'spinnerThird' : 'checkmark'}
            variant="primary"
            disabled={!createAgree || disabled}
            onClick={handleCreateCompany}
          >
            {saving ? 'Accepting...' : 'Accept invitation'}
          </Button>
        </div>
      </NavStepperFooter>
    </NavStepperCard>
  )
}
